$screen-xs-min: 375px;
$screen-sm-min: 576px;
$screen-md-min: 768px;

$dark: #303841;
$red: #ea5455;

@mixin xs {
    @media (min-width: #{$screen-xs-min}) and (max-width: #{$screen-sm-min}) {
        @content;
    }
 }

@mixin sm {
    @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }

:root {
    font-family: Montserrat, Helvetica, sans-serif;
}

body {
    margin: 0;
}

.btn {
    border-radius: 8px;
    border: none;
    color: white;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
}

.content-wrapper__title {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include xs {
        font-size: 2.5rem;
    }
}

.background {
    background: url("background_bw.jpg") no-repeat center center fixed;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 5rem 10rem 5rem 10rem;
    min-height: 15rem;

    @include xs {
        padding: 5rem 5rem 0 5rem;
    }
}

.red {
    background-color: $red;
}

.dark {
    background-color: $dark;
}

.white-text {
    color: white;
}

.contact-button-section {
    margin-top: 2rem;
}

.section--main {
    background: url("background_bw.jpg") no-repeat 45% 80%;
    padding-top: 12rem;
    padding-bottom: 12rem;
}

.section__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.section__title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
}

.section__subtitle {
    font-size: 1rem;
    margin-top: 1rem;
}

.werkzaamheden_widget__title {
    h3 {
        margin-bottom: 0.5rem;
    }
    text-align: center;
}

.werkzaamheden__wrapper {
    display: flex;
    flex-direction: column;
}

.werkzaamheden__images {
    display: flex;
    justify-content: space-around;
}

.werkzaamheden__text {
    display: flex;
}

.werkzaamheid {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
}

.werkzaamheid--left {
    margin-right: 0.5rem;
}

.werkzaamheid--right {
    margin-left: 0.5rem;
}

.circle .brush {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: url(brush.jpg);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
}

.circle .wood {
    display: inline-block;
    width: 100px;
    height: 100px;
    background: url(wood.jpg);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
}

.propaganda__wrapper {
    display: flex;
    margin-top: 1rem;
}

.propaganda__slogan {
    font-size: 2.5rem;
    font-weight: lighter;
    flex-basis: 0;
    flex-grow: 1;
    margin-right: 0.5rem;

    @include xs {
        font-size: 2rem;
        margin-right: 0.25rem;
    }
}

.propaganda__story {
    flex-basis: 0;
    flex-grow: 1;
    margin-left: 0.5rem;

    @include xs {
        margin-left: 0.25rem;
    }
}

.project-widget {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin-left: -2rem;
    margin-bottom: -2rem;
}

.project-widget__item {
    flex-shrink: 1;

    margin-left: 2rem;
    margin-bottom: 2rem;
}

.project-widget__image {
    max-width: 15rem;
    margin-bottom: 1rem;
    height: 20rem;
    object-fit: cover;
}

.project-image__title {
    font-weight: bold;
}

.project-widget__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form__details {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
}

.important {
    display: none;
}

.details__input {
    height: 2.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &+& {
        margin-left: 1rem;
    }

    @include sm {
        margin-left: 0;
        margin-top: 0.5rem;
        &+& {
            margin-left: 0;
            margin-top: 0.5rem;
        }
        flex-grow: 1;
    }
    
    @include xs {
        margin-left: 0;
        margin-top: 0.5rem;
        &+& {
            margin-left: 0;
            margin-top: 0.5rem;
        }
        flex-grow: 1;
    }
}

.contact-form__textarea {
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 5rem;
}

.contact-form__submit-wrapper {
    display: flex;
    justify-content: flex-end;
}

.contact-form__submit {
    margin-top: 0.5rem;
}

.contact-form__recaptcha {
    margin-top: 0.5rem;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5rem;
}

.footer__element {
    color: white;
    padding: 2rem;
    flex: 1;
    text-align: center;

    @include xs {
        padding: 0.75rem;
        font-size: 0.75rem;
    }
}

.phonenumber {
    color: white;
    text-decoration: none;
}

.phonenumber--red {
    color: $red;
    text-decoration: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
}

.modal-scrim {
    z-index: -1;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    transform: scale(1);
    opacity: 1;
    max-width: 67rem;
    width: 70%;
}

.modal-surface {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 32px);
    max-height: calc(100vh - 32px);
    min-width: 350px;
    background-color: white;
    border-radius: 0.5rem;
}

.modal-contents {
    flex: 1;
    display: flex;
    max-height: 100%;
}

.modal-close {
    margin-top: 0.5rem;
}

.modal-content {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.modal-image-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 0.5rem;
}

.modal-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.thumbnails {
    overflow-x: auto;
    // overflow-y: hidden;
    // flex: 0 0 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: end;
    margin-bottom: 0.5rem;
}

.thumbnail-container {
    position: relative;
    min-width: 4rem;
    height: 4rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}

.thumbnail {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
}

.selected-thumbnail {
    opacity: 0.5;
}